html.customer-app header {
  .navbar-brand {
    margin-right: 0;
  }

  .nav-link {
    color: #19434c !important;
    font-family: $sf-pro;
  }

  .main-menu {
    background-color: white;
    border-bottom: solid 1px $light-gray;

    @include media-breakpoint-up(sm) {
      nav {
        padding: 0px;
      }
    }

    line-height: 68px;
    color: #19434c;
    li.nav-item {
      flex: none;

      @include media-breakpoint-only(xs) {
        line-height: 15px;
        font-size: 20px;
        margin: 0 -1rem;
        padding: 0 1rem 0.5rem 1rem;
        border-bottom: 1px solid #d9dcec;

        &:last-child {
          border-bottom: none;
        }
      }

      @include media-breakpoint-up(sm) {
        padding: 0 4px;
        font-size: 0.9rem;
      }
      @include media-breakpoint-up(xl) {
        padding: 0 10px;
        font-size: 1.2em;
      }
      &.active {
        border: {
          bottom: 5px solid #0a6267;
        }
        background-color: #fbf9f4;
      }
    }
  }

  .logo {
    height: 28px;
    @media (min-width: 768px) {
      width: 185px;
    }
  }
}
