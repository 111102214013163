@import 'custom';
$fa-font-path: '~@fortawesome/fontawesome-free/webfonts';
@import '~@fortawesome/fontawesome-free/scss/fontawesome';
@import '~@fortawesome/fontawesome-free/scss/solid';
@import '~@fortawesome/fontawesome-free/scss/brands';
@import '~bootstrap/scss/bootstrap';

@import './components/spinner';
@import './components/custom_checkbox';

@import './devise';

@import './referrals';

// Redesigned components
@import './redesign/footer';
@import './redesign/navbar';

@import 'font-faces/recoleta';

$mon-ami-mint: #006468;
$sf-pro: -apple-system, BlinkMacSystemFont, Arial, sans-serif;

// Generic styles shared by the customer app
html.customer-app {
  body {
    display: flex;
    min-height: 100vh;
    flex-direction: column;
    color: #19434c;
    font-family: SourceSansPro, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
      'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji',
      'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji',
      'recoleta-regular', 'recoleta-medium';
  }

  .impersonation-alert {
    background-color: #0a6267;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: #19434c;
    font-family: 'recoleta-regular';
  }

  input,
  select,
  textarea,
  .field {
    border: solid 1px $mon-ami-mint;
  }

  main.content {
    font-family: $sf-pro;
    margin-bottom: 2rem;

    h1 {
      font-family: 'recoleta-regular';
      font-size: 30px;
      font-weight: 500;
      color: #19434c;
      border-bottom: 5px solid #ffd7d0;
      word-break: keep-all;
      max-width: fit-content;
    }

    flex: 1;
    margin-top: 1em;

    .back-link {
      height: 28px;
      font-size: 22px;
      font-weight: 500;
      color: #5364ae;
      margin: {
        top: 24px;
        bottom: 40px;
      }
    }
  }

  .empty {
    font-size: 20px;
    color: #19434c;
  }

  .alert {
    padding: 0;
    padding-right: 1rem;
    color: #525960;
  }

  .alert-danger {
    background-color: white;
    border-color: #ff6773;
  }

  .alert-info {
    background-color: white;
    border-color: #ffc20b;
  }

  .alert-success {
    background-color: white;
    border-color: #68d56d;
  }

  .alert-text {
    padding: 0.5rem;
  }

  .line-height {
    line-height: normal;
  }

  .box {
    color: white;
    text-align: center;
    padding: 1rem;
    font-size: 1rem;
  }

  .box-danger {
    background-color: #ff6773;
  }

  .box-info {
    background-color: #ffc20b;
  }

  .box-success {
    background-color: #68d56d;
  }

  .text-danger {
    font-weight: 600;
    color: #ff6773;
  }

  .text-info {
    font-weight: 600;
    color: #525960 !important;
  }

  .text-success {
    font-weight: 600;
    color: #68d56d;
  }
}
