// See all variables here:
// https://github.com/twbs/bootstrap/blob/v4.2.1/scss/_variables.scss

$primary: #ffc20d;
$secondary: #56ccf7;
$danger: #ff6674;
$gray: #9099a5;
$light-gray: #dbdfe4;
$dark-gray: #525960;
$cloudy-blue: #bac2cc;
$goldenrod: #ffc20b;
$lighter-green: #B6D1CF;
$light-green: #0A6267;
$dark-green: #19434c;
$purple: #5364ae;

$theme-colors: (
  "primary": $dark-green,
  "secondary": $purple,
  "danger": $danger,
  "gray": $gray,
  "light-gray": $light-gray,
  "dark-gray": $dark-gray,
  "warning": $goldenrod,
  "facebook-blue": #5077b2,
  "google-red": #db5335
);

$body-color: $dark-gray;
$headings-color: $dark-gray;
$body-bg: white;
$body-dark-bg: #f7f8f9;

$btn-link-disabled-color: $gray;

$link-color: $purple;
$link-hover-color: $dark-green;

$input-border: $light-gray;
$input-border-focus: $primary;
$input-border-focus: $primary;
$input-color-placeholder: $gray;

// overrides that variables can't handle

input {
  box-shadow: none !important;
  -webkit-box-shadow: none !important;
}

label {
  color: $gray;
}

.btn {
  border: none !important;
}
.btn-secondary:hover, .btn-secondary:focus {
  background-color: $link-hover-color !important;
  border-color: $link-hover-color !important;
}

.btn-secondary, .btn-gray {
  color: white !important;
}

.btn-rounded {
  border-radius: 50px !important;
}
