.referrals {
  h1 {
    margin-top: 3rem;
    margin-bottom: 2rem;
    font-size: 2rem;
  }

  .logo {
    width: 185px;
    height: 28px;
  }

  .main-menu {
    background-color: white;
    border-bottom: solid 1px #dbdfe4;
    line-height: 68px;
  }

  .footer {
    img.logo {
      max-width: 197px;
      width: auto;
    }

    a:hover {
      text-decoration: none;
    }

    .contact {
      a {
        color: $dark-gray;
      }
    }

    .privacy {
      a {
        color: #56ccf7;
      }
    }
  }

  form {
    margin-bottom: 5rem;

    .btn {
      margin-top: 2rem;
    }
  }
}
