@import './bootstrap_v4.scss';

$sf-pro: -apple-system, BlinkMacSystemFont, Arial, sans-serif;

html.devise {
  body {
    background-color: white !important;

    h1, h4, h5, label, .main-logo, .logo {
      color: #006468;
    }
  }

  .vaccine-login {
    border: 4px solid #f2e3d4;
    background-color: #f6ebdf;
    a {
      text-decoration: underline;
      color: #006468;
    }
  }

  main.content {
    margin-top: 0 !important;
  }

  &.sessions.new {
    body {
      font-family: $sf-pro;
      h1 {
        font-family: 'recoleta-regular';
        font-size: 3.4375rem;
      }
      .bg-light-yellow {
        background-color: #faf4ee;
      }
      .btn-secondary {
        background-color: #5E6FCF;
      }
      .form-group > a {
        color: #5E6FCF;
      }
      .companion-download-badges a {
        width: 100%;
        img { width: 100%; }
      }
      .btn {
        font-weight: 600;
      }
      img.sign-in-logo {
        @include media-breakpoint-down(sm) {
          margin: 0 auto;
        }
      }
    }
  }
}
