html.customer-app {
  .footer {
    font-family: $sf-pro;
    img.logo {
      max-width: 197px;
      width: auto;
    }
    .contact {
      a {
        color: #19434c;
        &:hover {
          text-decoration: none;
        }
      }
    }
    .fab {
      color: #5E6FCF;
    }
    a {
      color: #5E6FCF;
    }
  }
}
